import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'
import {/*HARD_MODE_DESCRIPTION,*/SETTINGS_MODONOCHE_TEXTO, 
SETTINGS_MODODALTONICOS_TEXTO, SETTINGS_MODODALTONICOS_DES, URL_BLOG, BOTONSOLUCIONES_TEXTO,CONTACTO_TEXTO_ANTES,CONTACTO_TEXTO_TXT, URL_CONTACTO, AUTOR_TEXTO_PREVIO, AUTOR_TEXTO_AUTOR, AUTOR_TEXTO_URL

} from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isHardMode,
  handleHardMode,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
}: Props) => {
  return (
    <BaseModal title="Ajustes" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col mt-2 divide-y">
       {/* <SettingsToggle
          settingName="Hard Mode"
          flag={isHardMode}
          handleFlag={handleHardMode}
          description={HARD_MODE_DESCRIPTION}
  />*/}
        <SettingsToggle
          settingName={SETTINGS_MODONOCHE_TEXTO}
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName={SETTINGS_MODODALTONICOS_TEXTO}
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={SETTINGS_MODODALTONICOS_DES}
        />
  {/*      <div className='flex justify-center gap-4 py-3'>
      <a href={URL_BLOG} target='_blank' rel='noreferrer'><button type="button" className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none soluciones"
>{BOTONSOLUCIONES_TEXTO}</button></a>
      </div>
*/}
        <div className='flex justify-between gap-4 py-3'>
        <p className="text-sm text-gray-500 dark:text-gray-300">
        {CONTACTO_TEXTO_ANTES} {' '}
        <a
          href={URL_CONTACTO}
          target='_blank'
          rel='noreferrer'
          className="underline font-bold"
        >{CONTACTO_TEXTO_TXT}</a>.
      </p></div>
       
      <div className='flex justify-center gap-4 py-3'>
        <p className="text-xs text-gray-500 dark:text-gray-300">
        {AUTOR_TEXTO_PREVIO}  {' '}
        <a
          href={AUTOR_TEXTO_URL} 
          target='_blank'
          rel='noreferrer'
          className="underline font-bold"
        >{AUTOR_TEXTO_AUTOR}</a>.
      </p></div>
      
      </div>
    </BaseModal>
  )
}
