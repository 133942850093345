export const WORDLE_TITLE = 'Madrid Wordle'
export const ABOUT_GAME_MESSAGE = 'Sobre Madrid Wordle'

export const HAS_GANADO = 'Has ganado'
export const WIN_MESSAGES = ['Eres más madrileño/a que la verbena de La Paloma!', 'Bien hecho, molas mazo!' ]
export const HAS_FALLADO = 'Has fallado'
export const LOST_MESSAGES = ['Tú no eres de Madrid','¡No me jodas, tronco/a!' ]

export const HASHTAG = '#MadridWordle'
export const URL = 'https://wordlema.angelvilches.com'
export const EMOJI_OK = '🟢'
export const EMOJI_PRESENT = '🟡'
export const EMOJI_ABSENT = '⚪'

export const HAY_BLOG = false
export const HAY_BANNER_PUBLI_EN_MODAL = false

export const REVELAMOS_SOLUCION = true
export const PUBLI_MODAL_ACIERTO_URL = ''
export const PUBLI_MODAL_ACIERTO_TEXTO = ''
export const HAY_ADSENSE = true
export const ADSENSE_CA = "ca-pub-1474100513598316"
export const ADSENSE_BANN_TECLADO = "1132028358"



export const AUTOR_TEXTO_PREVIO = 'Juego adaptado por'
export const AUTOR_TEXTO_AUTOR = 'Ángel Vilches'
export const AUTOR_TEXTO_URL = "https://angelvilches.com"


export const INFO_DESCRIPCION1 = "Adivina la palabra en 6 intentos. Después de cada intento, el color de las letras cambiará para indicarte si estás cerca o lejos de la solución."
export const INFO_DESCRIPCION2 = "Siempre será una palabra relacionada con Madrid (capital o Comunidad): calles, pueblos, barrios, personajes, historia, música, deporte, etc..."
export const INFO_PRIVACIDAD_ANTES = "Al jugar estás aceptando la"
export const INFO_PRIVACIDAD_PRIVA = "política de privacidad"
export const INFO_PRIVACIDAD_URL = "https://wordle.angelvilches.com/template/politica-privacidad.html"


export const URL_CONTACTO = "mailto:wordle@angelvilches.com"
export const URL_BLOG = "https://wordlema.angelvilches.com/madrid"
export const ACIERTO_TEXTO_BLOG = "+ info sobre la palabra de cada día en el BLOG"
export const FALLO_TEXTO_BLOG1 = "¿Cual era la palabra de hoy?"
export const FALLO_TEXTO_BLOG2 = "Mañana a las 7am la publicamos en el blog."


export const CONTACTO_TEXTO_ANTES = "Puedes proponer nuevas palabras o contactar para otros asuntos enviando un email a"
export const CONTACTO_TEXTO_TXT = "wordle@angelvilches.com"

export const SETTINGS_MODONOCHE_TEXTO = "Modo noche"
export const SETTINGS_MODONOCHE_DES = "Modo noche"
export const SETTINGS_MODODALTONICOS_TEXTO = "Modo daltónicos"
export const SETTINGS_MODODALTONICOS_DES = "Cambia los colores de las letras para ayudar a personas con esta dificultad"

export const BOTONSOLUCIONES_TEXTO = "SOLUCIONES"

export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Guess must contain ${letter}`
  export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `La palabra era ${solution}. Mañana tendrás una nueva palabra`
  export const GAME_COPIED_MESSAGE = 'Juego copiado al portapapeles'
export const STATISTICS_TITLE = 'Estadísticas'
export const GUESS_DISTRIBUTION_TEXT = 'Distribución'
export const NEW_WORD_TEXT = 'Nueva palabra para jugar en '
export const SHARE_TEXT = 'COMPARTIR'
export const TOTAL_TRIES_TEXT = 'Intentos totales'
export const SUCCESS_RATE_TEXT = 'Porcentaje de aciertos'
export const CURRENT_STREAK_TEXT = 'Racha actual'
export const BEST_STREAK_TEXT = 'Mejor racha'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Te faltan letras, deben ser 5.'
export const WORD_NOT_FOUND_MESSAGE = 'Palabra no encontrada en nuestro diccionario'
export const ENTER_TEXT = 'Intro'
export const DELETE_TEXT = 'Borrar'
export const HARD_MODE_ALERT_MESSAGE =
  'Hard Mode can only be enabled at the start!'
export const HARD_MODE_DESCRIPTION =
  'Any revealed hints must be used in subsequent guesses'