import { INFO_DESCRIPCION1, INFO_DESCRIPCION2,URL_CONTACTO,INFO_PRIVACIDAD_ANTES, INFO_PRIVACIDAD_PRIVA,INFO_PRIVACIDAD_URL,CONTACTO_TEXTO_ANTES,CONTACTO_TEXTO_TXT  } from '../../constants/strings'
import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="¿Cómo se juega?" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300 margen-inferior">
      {INFO_DESCRIPCION1}
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {INFO_DESCRIPCION2}
      </p>

      <div className="flex justify-center mb-1 mt-4">
      <Cell isRevealing={true} isCompleted={true} value="I" status="correct"/>
        <Cell value="F" />
        <Cell value="E" />
        <Cell value="M" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La letra <strong>I</strong> forma parte de la palabra y está en su posición correcta.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="A" />
        <Cell isRevealing={true} isCompleted={true} value="R" status="present"/>
        <Cell value="L" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La letra <strong>R</strong> forma parte de la palabra, pero no está en la posición correcta.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="U" />
        <Cell value="S" />
        <Cell value="E" />
        <Cell value="R"/>
        <Cell isRevealing={true} isCompleted={true} value="A"  status="absent" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300 margen-inferior">
        La letra <strong>A</strong> no está en la palabra. 
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      {CONTACTO_TEXTO_ANTES} {' '}
        <a
          href={URL_CONTACTO}
          target='_blank'
          rel='noreferrer'
          className="underline font-bold"
        >{CONTACTO_TEXTO_TXT}</a>.</p>
      <p className="text-xs text-gray-500 dark:text-gray-300 mt-4">
      {INFO_PRIVACIDAD_ANTES} {' '}
        <a
          href={INFO_PRIVACIDAD_URL}
          target='_blank'
          rel='noreferrer'
          className="underline font-bold"
        >{INFO_PRIVACIDAD_PRIVA}</a>.</p>
            </BaseModal>
  )
}
