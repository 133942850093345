export const WORDS = [
   'prado',
   'mayor',
   'colon',
   'villa',
   'parla',
   'campo',
   'ayuso',
   'wanda',
   'pinto',
   'lucio',
   'rozas',
   'angel',
   'reina',
   'fitur',
   'ardoz',
   'anton',
   'maura',
   'reyes',
   'vista',
   'ifema',
   'usera',
   'clara',
   'rosas',
   'duque',
   'pilar',
   'barca',
   'tirso',
   'cajal',
   'cuzco',
   'aznar',
   'oeste',
   'azaña',
   'ochoa',
   'artes',
   'arena',
   'sofia',
   'corpa',
   'pitis',
   'aldea',
   'verja',
   'pibas',
   'navas',
   'curie',
   'olmos',
   'paseo',
   'poeta',
   'talgo',
   'venta',
   'tilly',
   'opera',
   'feria',
   'gatos',
   'osuna',
   'cunef',
   'serna',
   'zaida',
   'pibon',
   'truño',
   'pacha',
   'ascao',
   'alamo',
   'magin',
   'silva',
   'ucles',
   'lucas',
   'sabio',
   'fraga',
   'monte',
   'ibiza',
   'esque',
   'gavia',
   'tapia',
   'godin',
   'nacha',
   'santo',
   'lista',
   'ramos',
   'peine',
   'museo',
   'palas',
   'mames',
   'diego',
   'rojas',
   'sainz',
   'sagra',
   'casar',
   'pirri',
   'nacho',
   'lapaz',
   'musas',
   'cielo',
   'nieto',
   'canal',
   'soria',
   'suero',
   'cubas',
   'jamon',
   'saenz',
   'pelas',
   'santa',
   'valle',
   'aires',
   'renta',
   'bravo',
   'molar',
   'meson',
   'turra',
   'marie',
   'boalo',
   'panda',
   'libro',
   'berna',
   'nueva',
   'renfe',
   'muela',
   'conde',
   'padre',
   'oliva',
   'batan',
   'tudor',
   'flipa',
   'velez',
   'bambu',
   'futre',
   'presa',
   'nebli',
   'plaza',
   'juana',
   'ramon',
   'oreja',
   'elipa',
   'silos',
   'debod',
   'pinar',
   'simpa',
   'vermu',
   'simon',
   'lemos',
   'udima',
   'moran',
   'zayas',
   'tours',
   'tucan',
   'viera',
   'perez',
   'embid',
   'ortiz',
   'nuevo',
   'salud',
   'leyva',
   'feten',
   'fondo',
   'viejo',
   'sella',
   'nuñez',
   'parda',
   'finde',
   'urgel',
   'maria',
   'gines',
   'pablo',
   'verde',
   'dario',
   'palma',
   'suker',
]
